.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 2vmin);
  height: 100%;
}

/* Mobile view styles */
@media (max-width: 767px) {
  .App {
    /* margin-top: 18%; */
  }
}
/* Desktop view styles */
@media (min-width: 768px) {
  .App {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
}
