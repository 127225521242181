.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 600px;
  height: auto;
  text-align: justify;
  font-family: "Nunito", sans-serif;
  font-size: large;
}

.model-element {
  margin: 10px;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 50px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.77);
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.message-input {
  padding: 10px;
  border-style: none;
  border-bottom: 1px solid black;
}

/* Mobile view styles */
@media (max-width: 767px) {
  .modal-content {
    width: 80%;
  }
}
